.phoneInput {
    border: 1px solid rgb(219, 219, 219);
    border-radius: 8px;
    height: 54px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0px !important;
    font-family: 'Satoshi-Medium' !important;
}


.phoneInputInput {
    border: none !important;
    background: transparent !important;
    font-size: 16px !important;
    font-family: 'Satoshi-Medium' !important;
    flex: 1;
    color: #051F73;
}

.phoneInputButton {
    border: none !important;
    background: transparent !important;
    background-color: transparent !important;
}

.selected-flag  {
    background-color: transparent !important;
    border-right: 1px solid rgba(0,0,0,.1);
    border-radius: 0px !important;
}

.phoneInputButton:hover {
    background-color: transparent !important;
}